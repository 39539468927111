<template>
  <div>
    <v-container>
      <div flat>
        <v-tabs v-model="tab" background-color="info" dark>
          <v-tab v-for="item in items" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.content">
            <div flat>
              <v-data-table
                :headers="dessertHeaders"
                :items="AllParcels"
                show-expand
                item-key="_id"
                :single-expand="true"
                hide-default-footer
                @page-count="pageCount = $event"
                class="elevation-1"
                :loading="TableLoading"
                loading-text="Loading... Please wait"
                :items-per-page="20"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span>{{ DisTime(item.createdAt) }}</span>
                </template>
                <template v-slot:item.maxKmReach="{ item }">
                  <span class="maxKm" v-if="item.maxKmReach"
                    >Above Coverage</span
                  >
                  <span class="maxKmNot" v-else>Within Coverage</span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-card-actions v-if="item.comment.length > 0">
                      <v-spacer></v-spacer>
                      <div class="text-center display-1 ma-3">
                        Ticket Issues
                      </div>
                      <v-spacer></v-spacer>
                      <IssueTicket :packageId="item._id" />
                      <ReAssignParcelToDifferentRider
                        :parcel="item"
                        :status="status"
                      />
                    </v-card-actions>
                    <v-simple-table dark v-if="item.comment.length > 0">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Name</th>
                            <th class="text-left">Message</th>
                            <th class="text-left">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(ite, index) in item.comment" :key="index">
                            <td>
                              {{ ite.fullName }}
                            </td>
                            <td>{{ ite.comment }}</td>
                            <td>{{ DisplayTimeFormat(ite.time) }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <div class="text-center display-1 ma-3">
                        Sender Details
                      </div>
                      <v-spacer></v-spacer>
                      <ReAssignParcelToDifferentRider
                        v-if="item.comment.length == 0"
                        :parcel="item"
                        :status="status"
                      />
                      <IssueTicket
                        :packageId="item._id"
                        v-if="item.comment.length == 0"
                      />
                    </v-card-actions>
                    <v-simple-table dark>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Sender Name</th>
                            <th class="text-left">Phone Number</th>
                            <th class="text-left">PickUp Address</th>
                          </tr>
                        </thead>
                        <tbody v-if="item.senderDetails">
                          <tr>
                            <td>
                              {{ item.senderDetails.firstName }}
                              {{ item.senderDetails.lastName }}
                            </td>
                            <td>{{ item.senderDetails.phoneNumber }}</td>
                            <td>{{ item.pickUpLocation.Street }}</td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td>
                              {{ item.offlineSenderDetails.firstName }}
                              {{ item.offlineSenderDetails.lastName }}
                            </td>
                            <td>{{ item.offlineSenderDetails.phoneNumber }}</td>
                            <td>{{ item.pickUpLocation.Street }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div class="text-center display-1 ma-3">
                      Delivery Details
                    </div>
                    <v-simple-table dark>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Contact Person</th>
                            <th class="text-left">Delivery Address</th>
                            <th class="text-left">Phone Number</th>
                            <th class="text-left">Distance Km</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ item.deliveryDetails.name }}</td>
                            <td>{{ item.deliveryDetails.Street }}</td>
                            <td>{{ item.deliveryDetails.mobile }}</td>
                            <td>{{ item.deliveryDetails.distance }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div
                      v-if="item.AddByStaff"
                      class="text-center display-1 ma-3"
                    >
                      Office Booking
                    </div>
                    <v-simple-table dark v-if="item.AddByStaff">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Staff Name</th>
                            <th class="text-left">Phone Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {{ item.AddByStaff.personalInfo.firstName }}
                              {{ item.AddByStaff.personalInfo.lastName }}
                            </td>
                            <td>
                              {{ item.AddByStaff.personalInfo.phoneNumber }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div
                      v-if="item.riderDetails"
                      class="text-center display-1 ma-3"
                    >
                      Rider Details
                    </div>
                    <v-simple-table dark>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Staff Name</th>
                            <th class="text-left">Phone Number</th>
                            <th class="text-left">Assign Time</th>
                            <th class="text-left">Current Location</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {{ item.riderDetails.personalInfo.firstName }}
                              {{ item.riderDetails.personalInfo.lastName }}
                            </td>
                            <td>
                              {{ item.riderDetails.personalInfo.phoneNumber }}
                            </td>
                            <td>{{ DisplayTimeFormat(item.assignTime) }}</td>
                            <td v-if="item.riderDetails.currentLocation">
                              {{ item.riderDetails.currentLocation.Street }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div class="text-center display-1 ma-3">Timeline</div>
                    <v-simple-table dark>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Action</th>
                            <th class="text-left">Phone Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="item.assignTime">
                            <td>Assign Time</td>
                            <td>
                              {{ DisplayTimeFormat(item.assignTime) }}
                            </td>
                          </tr>
                          <tr v-if="item.riderPickupTime">
                            <td>Pickup Time</td>
                            <td>
                              {{ DisplayTimeFormat(item.riderPickupTime) }}
                            </td>
                          </tr>
                          <tr v-if="item.completedTime">
                            <td>Delivery Time</td>
                            <td>
                              {{ DisplayTimeFormat(item.completedTime) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <div
                      v-if="item.reAssignStatus"
                      class="text-center display-1 ma-3"
                    >
                      Re-Assign History
                    </div>
                    <v-simple-table
                      v-if="item.reAssignStatus"
                      dark
                      class="mt-5"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">Rider Name</th>
                            <th class="text-center">Re-Assign By</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">ShippingFee</th>
                            <th class="text-center">Rider Payout</th>
                            <th class="text-center">Re-AssignShipping Fee</th>
                            <th class="text-center">assignTime</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            :key="ReAssignHistory.firstName"
                            v-for="ReAssignHistory in item.reAssignHistory"
                          >
                            <td class="text-center">
                              {{ ReAssignHistory.firstName }}
                              {{ ReAssignHistory.lastName }}
                            </td>
                            <td
                              v-if="ReAssignHistory.reAssignBy"
                              class="text-center"
                            >
                              {{ ReAssignHistory.reAssignBy.firstName }}
                              {{ ReAssignHistory.reAssignBy.lastName }}
                            </td>
                            <td class="text-center">
                              {{ ReAssignHistory.status }}
                            </td>
                            <td class="text-center">
                              {{ DisplayCurrency(ReAssignHistory.shippingFee) }}
                            </td>
                            <td class="text-center">
                              {{ DisplayCurrency(ReAssignHistory.riderPayout) }}
                            </td>
                            <td class="text-center">
                              {{
                                DisplayCurrency(
                                  ReAssignHistory.reAssignShippingFee
                                )
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                DisplayTimeFormat(ReAssignHistory.assignTime)
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <div class="text-center display-1 ma-3">Parcel Details</div>
                    <v-simple-table v-if="item.parcelItems" dark class="mt-5">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">Name</th>
                            <th class="text-center">Price</th>
                            <th class="text-center">QTY</th>
                            <th class="text-center">Deliver-QTY</th>
                            <th class="text-center">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            :key="parcel.name"
                            v-for="parcel in item.parcelItems"
                          >
                            <td v-if="parcel.name" class="text-center">
                              {{ parcel.name }}
                            </td>
                            <td v-if="parcel.name" class="text-center">
                              {{ DisplayCurrency(parcel.price) }}
                            </td>
                            <td v-if="parcel.name" class="text-center">
                              {{ parcel.qty }}
                            </td>
                            <td v-if="parcel.name" class="text-center">
                              {{ parcel.qtySold }}
                            </td>
                            <td v-if="parcel.name" class="text-center">
                              {{
                                DisplayCurrency(parcel.price * parcel.qtySold)
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-simple-table
                      v-if="item.parcelReturn && item.completedTime"
                      dark
                      class="mt-5"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">Rider Return Status</th>
                            <th class="text-center">Return With</th>
                            <th class="text-center">Confirmation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">
                              {{ item.returnStatus }}
                            </td>
                            <td class="text-center">
                              {{ item.returnWith }}
                            </td>
                            <td class="text-center">
                              <v-btn
                                color="pink"
                                :disabled="!item.ReturnTransit"
                                dark
                                @click="
                                  ConfirmReturn(item);
                                  dialog = !dialog;
                                "
                              >
                                Confirm Return
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="AwaitingPackageTotalItems"
        ></v-pagination>
      </div>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Return Policy
          </v-card-title>

          <v-card-text>
            Confirm Rider Return {{ item.returnWith }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!item.ReturnTransit"
              color="pink"
              @click="confirmParcel()"
              >Proceed
            </v-btn>
            <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import moment from "moment";
import IssueTicket from "../components/IssueTicket.vue";
import ReAssignParcelToDifferentRider from "../components/ReAssignParcelToDifferentRider.vue";
import currencyFormatter from "currency-formatter";

export default {
  components: {
    IssueTicket,
    ReAssignParcelToDifferentRider,
  },
  data() {
    return {
      dialog: false,
      item: {},
      expanded: [],
      singleExpand: false,
      tab: null,
      items: [
        { tab: "Awaiting Pickup Parcel", content: "awaitingPickup" },
        { tab: "Transit Parcel", content: "transit" },
        { tab: "Issue Parcel", content: "deliver" },
        { tab: "Deliver Parcel", content: "issues" },
      ],
      dessertHeaders: [
        {
          text: "Tracking No",
          align: "start",
          value: "trackingNumber",
        },
        { text: "Pickup Date", value: "pickUpDate" },
        { text: "Pickup Address", value: "pickUpLocation.Street" },
        { text: "Request Type", align: "start", value: "RequestType" },
        { text: "Max KM", value: "maxKmReach" },
      ],
      status: "awaitingPickup",
      page: 1,
      Currpage: 1,
    };
  },
  computed: {
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    AwaitingPackageTotalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
    AllParcels() {
      return this.$store.getters.AllParcels;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    confirmParcel() {
      let payload = {
        token: this.getToken,
        parcelId: this.item._id,
      };
      this.$store.dispatch("ConfirmParcelReturn", payload);
      this.dialog = false;
    },
    ConfirmReturn(payload) {
      this.item = payload;
    },
    DisTime(payload) {
      return moment(payload).calendar();
    },
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
    GetPackages() {
      let payload = {
        page: this.page,
        token: this.getToken,
        status: this.status,
      };
      this.$store.dispatch("GetAllParcels", payload);
    },
  },
  created() {
    this.GetPackages();
  },
  watch: {
    tab: function (val) {
      if (val == 0) {
        this.status = "AssignToRider";
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
          status: "AssignToRider",
        };
        this.$store.dispatch("GetAllParcels", payload);
      }
      if (val == 1) {
        this.status = "Transit";
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
          status: "Transit",
        };
        this.$store.dispatch("GetAllParcels", payload);
      }
      if (val == 3) {
        this.status = "Delivery Completed";
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
          status: "Delivery Completed",
        };
        this.$store.dispatch("GetAllParcels", payload);
      }
      if (val == 2) {
        this.status = "issues";
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
          status: "issues",
        };
        this.$store.dispatch("GetAllParcels", payload);
      }
    },
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
          status: this.status,
        };
        this.$store.dispatch("GetAllParcels", payload);
        return;
      }
    },
  },
};
</script>

<style scoped>
.maxKmNot {
  padding: 10px;
  background-color: green;
  color: white;
}
.maxKm {
  padding: 10px;
  background-color: red;
  color: white;
}
</style>