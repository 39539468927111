<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="warning"
          dark
          @click="Cancel(true)"
        >
          Issue Ticket
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Issue Ticket
        </v-card-title>

        <v-textarea
          v-model="message"
          filled
          auto-grow
          label="Message"
          rows="4"
          row-height="30"
          shaped
          class="ma-4"
        ></v-textarea>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="Cancel(false)">Cancel</v-btn>
          <v-btn color="pink" @click="send">Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["packageId"],
  data() {
    return {
      message: "",
    };
  },
  computed: {
    dialog() {
      return this.$store.getters.dialog;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    Cancel(payload) {
      this.$store.commit("setDialog", payload);
    },
    send() {
      if (this.message == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Message is required");
        return;
      }
      let payload = {
        token: this.getToken,
        message: this.message,
        parcelId: this.packageId,
      };
      this.$store.dispatch("issueTicket", payload);
    },
  },
  watch: {
    dialog: function () {
      this.message = "";
    },
  },
};
</script>